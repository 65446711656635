import * as React from "react"
import loadable from '@loadable/component'

import Layout from "../components/layout/layout"
import CapabilitiesSection from "../components/sections/capabilities-section"
import Seo from "../components/seo"
import CtaSection from "../components/sections/cta-section"
import PageContactSection from "../components/sections/page-contact-section"

const HubspotContact = loadable(() => import('../components/common/hubspot-contact'))

const ContactPage = () => (
  <Layout>
    <Seo title="Contact Us" />
    <div className="bg-blue-100 py-12">
      <div className="w-11/12 max-w-xl mx-auto">
        <HubspotContact />
      </div>
    </div>
    <PageContactSection />
    <CtaSection />
    <CapabilitiesSection />
  </Layout>
)

export default ContactPage
