import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const PageContactSection = () => (
  <div className="bg-gray-900 w-full py-12">
    <div className="w-11/12 max-w-screen-xl flex flex-col-reverse xl:flex-row items-center justify-center mx-auto">
      <div className='w-full lg:w-1/2 p-4'>
        <h2 className='text-3xl md:text-4xl text-blue-100 font-bold mb-1'>Best IT Services</h2>
        <p className='text-lg text-white mb-8'>Need help solving an IT Problem?</p>
        <h2 className='text-xl md:text-2xl text-blue-100 font-bold mb-1'>Corporate Headquarters</h2>
        <p className='text-lg text-white mb-8'>1314 W 5th Street, 2nd Floor, Santa Ana, CA 92703</p>
        <h2 className='text-xl md:text-2xl text-blue-100 font-bold mb-4'>Office Number</h2>
        <a href="tel:9495430234" className="w-full bg-red-600 hover:bg-red-700 text-white font-display px-6 py-2 font-semibold uppercase tracking-wider rounded">
          (949) 543-0234
        </a>
        <h2 className='text-xl md:text-2xl text-blue-100 font-bold mt-8 mb-1'>Office Hours</h2>
        <p className='text-lg text-white mb-8'>Monday to Friday, 8:00 AM to 6:00 PM</p>
        <h2 className='text-xl md:text-2xl text-blue-100 font-bold mb-4'>After Hours Emergency Support</h2>
        <a href="tel:9495430229" className="w-full bg-red-600 hover:bg-red-700 text-white font-display px-6 py-2 font-semibold uppercase tracking-wider rounded">
          (949) 543-0229
        </a>
      </div>

      <div className='w-full lg:w-1/2 p-4'>
        <a href="https://goo.gl/maps/TaskwKAx8i52" target="_blank" rel="noopener noreferrer">
          <StaticImage
            src="../../images/contact/map-new.png"
            width={597}
            layout="constrained"
            alt=""
            placeholder='tracedSVG'
            formats={["auto", "webp", "avif"]}
            className="rounded-md shadow-lg"
          />
        </a>
      </div>
    </div>
  </div>
)

export default PageContactSection